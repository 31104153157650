const TableHeaders = () => {
  return (
    <tbody className="entregas-table-headers">
      <tr>
        <th>Coordinadora</th>
        <th>Artesana</th>
        <th>Código producto</th>
        <th>Descripción producto</th>
        <th>Cantidad</th>
        <th>$ Total</th>
      </tr>
    </tbody>
  );
};

export { TableHeaders };
