import styled from "styled-components";

const Wrapper = styled.div`
  position: relative;
`;

const EmptyBar = styled.div`
  height: 0.75vh;

  background: #dcd7d7;

  border-radius: 30px;
`;

const ProgressBar = styled.div`
  width: ${(props) => props.width};
  height: 0.75vh;
  position: absolute;
  top: 0;
  background: ${(props) => (props.background ? props.background : "#339589")};

  border-radius: 30px;
`;

export { Wrapper, EmptyBar, ProgressBar };
