import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { client } from "utils/api-client";

const errors = [401, 403, 404, 500];

/* const useGetProductByid = (endpoint, id) => {
  const { data, isLoading, isError, isSuccess } = useQuery(
    {
      queryKey: ["product", id],
      queryFn: () => client(`admin/${endpoint}/${id}`).then((data) => data),

      useErrorBoundary: (error) =>
        errors.includes(parseInt(error.response?.status)),
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      //disabled if id is undefined
      enabled: !!id,
    },
    {
      onError: (err) => {
        throw new Error(err);
      },
    }
  );
  return {
    product: data ?? {},
    isLoading,
    isError,
    isSuccess,
  };
}; */

const useGetProductCharacteristic = (endpoint) => {
  const { data, isLoading, isError, isSuccess } = useQuery(
    {
      queryKey: [endpoint],
      queryFn: () => client(`admin/${endpoint}/`).then((data) => data),

      useErrorBoundary: (error) =>
        errors.includes(parseInt(error.response?.status)),
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      cacheTime: Infinity,
    },
    {
      onError: (err) => {
        throw new Error(err);
      },
    }
  );
  return {
    data: data ?? [],
    isLoading,
    isError,
    isSuccess,
  };
};

const useAddProduct = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (data) =>
      client("admin/products/", {
        method: "POST",
        data,
      }),
    {
      onSuccess: (data) => queryClient.refetchQueries(["products"]),
      onError: (err) => {
        throw new Error(err);
      },
    }
  );
};

const useEditProduct = (id) => {
  const queryClient = useQueryClient();
  return useMutation(
    (data) =>
      client(`admin/products/${id}/`, {
        method: "PUT",
        data,
      }),
    {
      onSuccess: (data) => queryClient.invalidateQueries(["product", id]),
      onError: (err) => {
        throw new Error(err);
      },
    }
  );
};

const useDeleteProduct = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (productId) =>
      client(`admin/products/${productId}/`, {
        method: "DELETE",
      }),
    {
      onSuccess: () => queryClient.refetchQueries(["products"]),
    }
  );
};

const useAddImageToProduct = (id) => {
  const queryClient = useQueryClient();

  return useMutation(
    async (data) => {
      for (let i = 0; i < data.pictures.length; i++) {
        await client(`admin/products/${data.id}/pictures/`, {
          method: "POST",
          data: data.pictures[i].full_file,
          headers: {
            "Content-Type": data.pictures[i].full_file.type,
            "Content-Disposition": `attachment; filename=${data.pictures[i].full_file.name}`,
            filename: data.pictures[i].full_file.name,
          },
        });
      }
    },
    {
      onSuccess: () => queryClient.invalidateQueries(["product", id]),
    }
  );
};

const useDeleteImageFromProduct = (id) => {
  return useMutation((data) =>
    client(`admin/products/${id}/pictures/${data.picture_id}`, {
      method: "DELETE",
    })
  );
};

export {
  /*   useGetProductByid, */
  useGetProductCharacteristic,
  useAddProduct,
  useEditProduct,
  useDeleteProduct,
  useAddImageToProduct,
  useDeleteImageFromProduct,
};
